import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import en_US from 'antd/lib/locale/en_US';


// import moment from 'moment';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHookProvider  } from '@apollo/client';
import { ApolloClient, InMemoryCache, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
// import { InMemoryCache  } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";

import './index.css';

import store from './store';
import './i18n';
import reportWebVitals from './reportWebVitals';
import Preloader from './components/Preloader';

const App = lazy(() => import("./App"));

const API_URL = process.env.REACT_APP_API_URL;

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
};


const uploadLink = createUploadLink({ 
  uri: process.env.REACT_APP_API_URL || 'http://localhost:5000/graphql',
  credentials: "include",

});

/**
 * create web socket links
 */

// GraphQL WebSocket (subscriptions) URL.
// If its url is not set in .env then it has same url, host and pathname
const WEBSOCKET_API_URL = process.env.REACT_APP_WEBSOCKET_API_URL;

const websocketApiUrl = WEBSOCKET_API_URL
  ? WEBSOCKET_API_URL
  : API_URL.replace('https://', 'ws://').replace('http://', 'ws://');

const wsLink: any = new WebSocketLink({
  uri: websocketApiUrl,
  options: {
    timeout: 60000,
    reconnect: true,
    // connectionParams: {
    //   authorization: authToken,
    // },
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
   if (graphQLErrors) {
     graphQLErrors.map(({ message, locations, path }) =>
       console.log(
         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
       ),
     );
   }
 
   if (networkError) {
     console.log(`[Network error]: ${networkError}`);
   }
 });

 const authLink = setContext((_, { headers }) => {

   return {
       headers: {
           ...headers,
       }
   }
})

  // Temporary fix for early websocket closure resulting in websocket connections not being instantiated
  // https://github.com/apollographql/subscriptions-transport-ws/issues/377
  wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () =>
    wsLink.subscriptionClient.maxConnectTimeGenerator.max;

  // Split links, so we can send data to each link
  // depending on what kind of operation is being sent
  const terminatingLink = split(
    ({ query }: any) => {
      const { kind, operation }: any = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    uploadLink
  );




const client = new ApolloClient({
   link: ApolloLink.from([
      authLink,
      errorLink, 
      terminatingLink as any
     ]) as any,
   cache: new InMemoryCache({
     typePolicies: {
       Query: {
         fields: {
          //  courses: {
          //    keyArgs: [],
          //  },
            departments: {
             keyArgs: [],
          }
         }
       }
     }
   }) as any,
   credentials: 'include'

 });


ReactDOM.render(
  
     <ApolloProvider client={client as any}>
         <ApolloHookProvider client={client}>
         <Provider store={store}>
          <ConfigProvider locale={en_US}>
         
             <Suspense fallback={<Preloader />}>
                 <ThemeSwitcherProvider themeMap={themes} defaultTheme={"light"}>
                   <App />
                 </ThemeSwitcherProvider>
            </Suspense>

          </ConfigProvider>
          </Provider>
         </ApolloHookProvider>
     </ApolloProvider>
     ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
